<template>
  <v-card elevation="4" max-width="996" width="100%" rounded="lg" class="dok-block" color="white">
    <v-window v-model="step">
      <v-window-item :value="1">
        <v-row>
          <v-col cols="12" md="6">
            <v-img class="mb-2" heigth="40" max-width="184" src="@/assets/img/logo-long.svg"></v-img>
            <v-btn class="mb-2" to="/info" text color="gray"><v-icon left>mdi-chevron-left mdi-24px</v-icon>{{ $t('btn.back') }}</v-btn>
            <div class="f30 font-weight-bold mb-2">{{ $t('setting.selectDoc') }}</div>
            <div class="gray--text mb-5">{{ $t('setting.selectDocDesc') }}</div>
          </v-col>
        </v-row>
        <v-card width="100%" rounded="lg" outlined class="mb-9">
          <v-list class="pa-0">
            <v-list-item-group v-model="selected" active-class="active-icon primary rounded-lg">
              <v-list-item v-for="(item, i) in $t('setting.kycItems')" :key="i">
                <v-list-item-icon class="mt-5">
                  <v-img height="24" max-width="24" :src="getImage(items[i].icon)" class="icon"></v-img>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="f18" v-text="item"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
        <div class="text-center">
          <v-btn to="/question/done" depressed height="64" width="228" color="white">{{ $t('btn.skip') }}</v-btn>
          <v-btn @click="step++" :disabled="selected == null" depressed height="64" width="228" color="primary">
            {{ $t('btn.confirm') }}
          </v-btn>
        </div>
      </v-window-item>
      <v-window-item :value="2">
        <v-img class="mb-6" heigth="51" max-width="65" src="@/assets/img/logo.svg"></v-img>
        <div class="f30 font-weight-bold mb-5">{{ $t('setting.uploadId') }}</div>
        <div class="font-weight-bold mb-4">{{ $t('setting.importantTitle') }}</div>
        <v-row class="mb-5">
          <v-col class="py-1" cols="12" md="5" v-for="item in $t('setting.importantList')" :key="item">
            <div class="important-item">{{ item }}</div>
          </v-col>
        </v-row>
        <v-card rounded="lg" flat height="250" class="d-flex justify-center align-center block-img">
          <div class="text-center">
            <div v-if="!file">
              <v-img class="mb-5 mx-auto" heigth="64" max-width="64" src="@/assets/icon/upload.svg"></v-img>
              <div class="gray--text mb-4">{{ $t('setting.fileDowload') }}</div>
            </div>
            <div v-else>
              <v-img class="mb-4 mx-auto" heigth="58" max-width="58" src="@/assets/icon/checked.svg"></v-img>
              <div class="gray--text mb-4">{{ file[0].name }}</div>
            </div>
            <v-btn depressed @click="onFileChange" class="rounded text-body-2 primary" width="104" height="30">
              {{ $t('btn.upload') }}
            </v-btn>
          </div>
          <input type="file" @change="onFileChange" />
        </v-card>
        <div class="d-flex flex-wrap-reverse justify-center">
          <v-btn @click="step--" depressed height="64" width="228" color="white">{{ $t('btn.back') }}</v-btn>
          <v-btn @click="action" :disabled="!file" depressed height="64" width="228" class="primary">{{ $t('btn.confirm') }}</v-btn>
        </div>
      </v-window-item>
    </v-window>
  </v-card>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      step: 1,
      selected: null,
      items: [
        { type: 'passport', icon: 'global' },
        { type: 'drivers_license', icon: 'car' },
        { type: 'id_card', icon: 'id_card' },
        { type: 'residence_permit_card', icon: 'home_alt_outline' },
      ],
      file: '',
      error: [],
    };
  },
  methods: {
    getImage(icon) {
      return require(`@/assets/icon/${icon}.svg`);
    },
    changeImg() {
      const input = document.createElement('input');
      const self = this;
      input.setAttribute('type', 'file');
      input.setAttribute('accept', 'image/*');
      input.addEventListener('change', function(e) {
        if (this.files && this.files[0]) {
          self.file = this.files[0];
          var reader = new FileReader();
          reader.readAsDataURL(this.files[0]);
        }
      });
      input.click();
    },
    onFileChange(e) {
      this.file = e.target.files || e.dataTransfer.files;
      if (!this.file.length) {
        return;
      }
    },
    async action() {
      this.error = [];
      const formData = new FormData();
      formData.append('id_type ', this.items[this.selected].type);
      formData.append('id_front ', this.file[0]);
      await this.$store
        .dispatch('updateProfileKyc', formData)
        .then(() => {
          // this.$router.push('/question/start');
          this.$router.push('/question/done');
        })
        .catch((e) => {
          this.error = e.response.data.error;
        });
    },
  },
};
</script>

<style lang="scss">
.dok-block {
  padding: 22px 94px;
  .active-icon {
    .icon {
      filter: invert(100%) sepia(0%) saturate(7461%) hue-rotate(335deg) brightness(97%) contrast(109%);
    }
    .v-list-item__title {
      color: white;
    }
  }
  .v-list-item {
    height: 65px;
  }
  .block-img {
    background-image: url("data:image/svg+xml;utf8,<svg width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'><rect width='100%' height='100%' style='fill: none; stroke: lightgray; opacity: 0.5; stroke-width: 4; stroke-dasharray: 10 10'/></svg>");
    margin-bottom: 24px;
  }
  .important-item {
    font-size: 14px;
  }
  .important-item::before {
    content: '\2022';
    padding-right: 10px;
    font-size: 16px;
  }
  input[type='file'] {
    cursor: pointer;
    display: block;
    height: 100%;
    left: 0;
    opacity: 0 !important;
    position: absolute;
    top: 0;
    width: 100%;
  }
  @media screen and (max-width: 599px) {
    padding: 36px 16px;
    border-radius: 0 !important;
    box-shadow: none !important;
    min-height: 100vh;
  }
}
</style>
